// stale listeners can cause memory loss, dry skin, and tooth decay
export function eventJanitor() {
    const removers = [];
    return {
        addListener: (target, type, listener, options) => {
            target.addEventListener(type, listener, options);
            removers.push(() => target.removeEventListener(type, listener, options));
        },
        removeAll: () => {
            removers.forEach(r => r());
            removers.length = 0;
        },
    };
}
